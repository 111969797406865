import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import { catchError, filter, take, switchMap, finalize } from 'rxjs/operators';
import { KeycloakService } from 'keycloak-angular';
import moment from 'moment';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
  private keycloakAuth = inject(KeycloakService).getKeycloakInstance();
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private lastActivityTime: number = Date.now();
  private readonly IDLE_TIMEOUT = 30; // 30 minutes
  private readonly IDLE_DIALOG_TIMEOUT = 120; // 120 minutes (dialog is open)
  private idleCheckInterval: any;
  private dialogIsOpen = false;
  private dialogIsOpened: number = Date.now();

  constructor() {
    // Start monitoring user activity
    this.startActivityMonitoring();
  }

  private startActivityMonitoring(): void {
    // Monitor user activity
    window.addEventListener('mousemove', () => this.resetIdleTimer());
    window.addEventListener('keypress', () => this.resetIdleTimer());
    window.addEventListener('click', () => this.resetIdleTimer());
    window.addEventListener('scroll', () => this.resetIdleTimer());

    // Check for idle time every minute
    this.idleCheckInterval = setInterval(() => {
      this.dialogIsOpened = this.dialogIsOpen ? Date.now() : this.lastActivityTime;
      this.checkIdleTime();
    }, 60000); // One check per minutes
  }

  private resetIdleTimer(): void {
    this.lastActivityTime = Date.now();
  }

  private checkIdleTime(): void {
    const dialog = document.getElementsByTagName('mat-dialog-container');
    this.dialogIsOpen = dialog.length > 0 || false;

    this.keycloakAuth.updateToken(30).then((refreshed: boolean) => {
      if (refreshed) {
        console.log('Token was successfully refreshed');
      } else {
        console.log('Token is still valid');
      }
      if(this.dialogIsOpen) {
        // console.log('checkIdleTime dialog is open', moment(this.dialogIsOpened - this.lastActivityTime).minutes() + 1, 'minutes');
        const openTime = moment(this.dialogIsOpened - this.lastActivityTime).minutes();
        if(openTime >= this.IDLE_DIALOG_TIMEOUT) {
          this.handleIdleTimeout();
        }
      } else {
        const currentTime = Date.now();
        const idleTime = moment(currentTime - this.lastActivityTime).minutes() ;
        // console.log('checkIdleTime dialog is not open', Math.floor(idleTime) + 1, 'minutes');
        if (idleTime >= this.IDLE_TIMEOUT) {
          this.handleIdleTimeout();
        }
      }
    }).catch(() => {
      this.handleIdleTimeout();
    });
  }

  private handleIdleTimeout(): void {
    // console.log('User has been idle, logging out...');
    this.keycloakAuth.logout();
  }

  private refreshToken(): Observable<any> {
    if (this.refreshTokenInProgress) {
      return this.refreshTokenSubject.pipe(
        filter(token => token !== null),
        take(1)
      );
    }

    this.refreshTokenInProgress = true;
    this.refreshTokenSubject.next(null);

    return new Observable(observer => {
      this.keycloakAuth
        .updateToken(30)
        .then(refreshed => {
          if (refreshed) {
            // console.log('Token refresh success');
            this.refreshTokenSubject.next(this.keycloakAuth.token);
            observer.next(this.keycloakAuth.token);
            observer.complete();
          } else {
            observer.error('Token refresh failed');
          }
        })
        .catch(error => {
          this.refreshTokenSubject.error(error);
          observer.error(error);
        })
        .finally(() => {
          this.refreshTokenInProgress = false;
        });
    });
  }

  private addToken(request: HttpRequest<any>): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.keycloakAuth.token}`
      }
    });
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Reset idle timer on each request
    this.resetIdleTimer();

    return next.handle(this.addToken(request)).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          return this.refreshToken().pipe(
            switchMap(token => {
              return next.handle(this.addToken(request));
            }),
            catchError(refreshError => {
              // If refresh fails, redirect to login
              this.keycloakAuth.login({
                scope: 'offline_access'
              });
              return throwError(() => refreshError);
            })
          );
        }
        return throwError(() => error);
      }),
      finalize(() => {
        // Any cleanup needed after request completes
      })
    );
  }

  ngOnDestroy() {
    if (this.idleCheckInterval) {
      clearInterval(this.idleCheckInterval);
    }
    // Remove event listeners
    window.removeEventListener('mousemove', () => this.resetIdleTimer());
    window.removeEventListener('keypress', () => this.resetIdleTimer());
    window.removeEventListener('click', () => this.resetIdleTimer());
    window.removeEventListener('scroll', () => this.resetIdleTimer());
  }
}
