<app-spinner></app-spinner>

<div class="container-fluid top-container">
  <div class="row p-0">
    <div class="col-md-12 p-0">
      <div class="container" style="width: 1200px">
        <div class="row align-items-center">
          <div class="col-1 p-0 logo-wrapper">
            <a class="cursor-pointer" (click)="onNavigate('/home')">
              <img alt="{{pageTitle}}" src="assets/images/SuG_smartTomm_2019_RGB{{logoSmartTomm}}.png">
            </a>
          </div>
          <div class="col title">{{pageTitle}}</div>
          <div class="col text-end">
            <a href="javascript:void(0)" class="sidebar-link" (click)="onSidebarToggle()"><fa-icon [icon]="faBars" size="lg"></fa-icon></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid p-0 pt-2 container-wrapper">
  <router-outlet></router-outlet>
</div>

<div class="container-fluid bottom-container">
  <div class="row p-0">
    <div class="col-md-12 p-0 d-flex align-items-center bottom-container-wrapper">
      <div class="container">
        <div class="row align-items-start">
          <div class="col p-0">
            <div class="container-fluid" style="width:1200px">
              <div class="row">
                <div class="col-md-4 d-flex align-items-center">
                  <span class="float-start powered-label">powered by</span>
                  <span class="float-start ms-3">
                    <a routerLink="/home">
                      <img alt="{{pageTitle}}" class="{{logo}}" src="assets/images/{{logo}}.png">
                    </a>
                  </span>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                  <app-appversion></app-appversion>
                </div>
                <div class="col-md-2 pe-0 d-flex align-items-center justify-content-end">
                  @if(!hasRoleRtgOrCustomer) {
                    <div class="meta-nav ml-auto">
                      <ul>
                        <li>
                          <a routerLink="/faq">Hilfe & FAQ</a>
                        </li>
                      </ul>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="sidebar" [class.open]="open$ | async">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-2"><fa-icon [icon]="faTimes" (click)="onSidebarToggle()" size="2x" class="text-white cursor-pointer"></fa-icon></div>
      <div class="col-md-10 text-white">
        <smart-drop-down-list
          #dropdownCompaniesRef
          class="w-100"
          onwheel="event.preventDefault();"
          [hidden]="hasRoleRtgOrCustomer"
          (onChange)="onCompanyChange($event)"
          [filterable]="true"
          [filterInputPlaceholder]="'Suche nach Kunde'"
          [theme]="'sidebar'"
          [selectedIndexes]="$any(selectedCompanyIndex$ | async)"
          [horizontalScrollBarVisibility]="'hidden'"
          [dataSource]="companiesList$ | async"
          [placeholder]="'Kunde wählen'"
        ></smart-drop-down-list>
      </div>
    </div>
    <div class="row mt-5 ms-2">
      <div class="col-md-12">
        <fa-icon (click)="onNavigate('/home')" [icon]="faHome" size="lg" class="text-white cursor-pointer"></fa-icon>
      </div>
    </div>
    <div class="row mt-4 ms-2">
      <div class="col-md-12">
        <ul class="p-0">
          @for(item of menuUser; track item) {
            <li>
              <a class="sidebar-nav-link cursor-pointer" [ngClass]="{ 'fw-bolder': router.url === item.route }" (click)="onNavigate(item.route)">
                {{ item.name }}
              </a>
            </li>
          }
        </ul>
      </div>
    </div>
    <div class="row mt-4 ms-2">
      <div class="col-md-12">
        <a (click)="logout()" class="sidebar-nav-link logout cursor-pointer">
          <fa-icon [icon]="faLogout" size="lg" class="text-white cursor-pointer me-2"></fa-icon>Logout
        </a>
      </div>
    </div>
  </div>
</div>
