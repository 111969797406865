import { BaseHttpService } from '@ems/api';
import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { HttpCacheManager } from '@ngneat/cashew';

@Injectable({providedIn: 'root'})
export class ContactMediumService extends BaseHttpService {
  override cacheEnabled = true;

  constructor(
    http: HttpClient,
    cookieService: CookieService,
    manager: HttpCacheManager,
  ) {
    super(http, cookieService, manager);
  }

  getAllContactMediumByContactId(contactId: number, params?: any) {
    const queryParams = {
      'filter[contact_id][eq]': contactId,
      'expand[0]': 'medium',
      'expand[1]': 'medium.mediaCategory',
      'expand[2]': 'company',
      ...params
    };
    return this.getAll('contact-medium', queryParams);
  }

  getAllContactMediumByMediaId(mediaId: number, params: any): Observable<any> {
    const queryParams = {
      'filter[medium_id][eq]': mediaId,
      'expand[0]': 'contact.employer.mailingAddress',
      'expand[1]': 'company',
      ...params
    }
    return this.get('contact-medium', queryParams);
  }

  create(payload: any): Observable<any> {
    this.invalidateCache();
    const params = {
      'expand[0]': 'contact.employer.mailingAddress.country',
      'expand[1]': 'updatedBy',
    };
    return this.post('contact-medium', payload, {}, params);
  }

  updateContactMediaRelation(relationBody: any, id: number): Observable<any> {
    const params = {
      'expand[0]': 'contact.employer.mailingAddress.country'
    };
    return this.post('contact-medium/' + id, relationBody, {}, params);
  }

  batchCreate(relationBody: any, id: number): Observable<any> {
    return this.post('contact-medium/batch-create/' + id, relationBody, {});
  }

  batchUpdate(from_contact_id: number, to_contact_id: number): Observable<any> {
    return this.post('contact-medium/batch-update-contact', { from_contact_id, to_contact_id }, {});
  }

  batchDelete(payload: any): Observable<any> {
    this.invalidateCache();
    return this.delete('contact-medium/batch-delete', payload);
  }
}
